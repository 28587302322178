import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Mui from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  MoreVert as IconMore,
  Delete as IconDelete,
  Cancel as IconCancel,
  Edit as IconEdit,
  LocalAtm as IconLocalAtm,
  SyncAlt as IconSyncAlt,
  OpenInNew as IconOpenInNew
} from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';

import { TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency, formatNumber } from '~/utils/format';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import CartEmpty from '~/components/CartEmpty';
import ModalCondicaoPagamentoProduto from '../../modal/ModalCondicaoPagamentoProduto';
import ModalSaldoEstoqueProduto from '../../modal/ModalSaldoEstoqueProduto';
import ModalConversorUnidadeMedida from '../../modal/ModalConversorUnidadeMedida';
import ModalEditarQuantidade from '../../modal/ModalEditarQuantidade';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0 6px 0  0'
  },
  container: {
    flex: 1,
    height: '100%'
  }
}));

const ListaItens = () => {
  const { venda, tipoDocumento } = useSelector(state => state.pdv);
  const parametroPF050 = useSelector(selectorParametroAsBoolean('PF050'))
  const itensFiltrados = venda?.itens.filter(item => !parametroPF050 || !item.itemCancelado)
  const classes = useStyles();

  return (
    <TableContainer className={classes.container} id="TableContainer">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.root}>
                <Grid container item>
                  <Grid container item md={1} justify="center" alignItems="center">
                    Item
                  </Grid>
                  <Grid container item md={7}>
                    Descrição (EAN/GTIN)
                  </Grid>
                  <Grid container item md={2} justify="flex-end" alignItems="center">
                    Valor Total
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {venda?.itens?.length === 0 ? (
              <CartEmpty />
            ) : (
              <>
                {itensFiltrados.map((item, index) => (
                  <ItemVendido key={`${item.itemOrdem}-${index}`} item={item} index={index +1} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
    </TableContainer>
  );
};

const ItemVendido = React.memo(({ item, index }) => {
  const dispatch = useDispatch();
  const isOnline = useSelector(getApplicationIsOnline());
  const { empresa } = useSelector(state => state?.auth);

  const [showModalCondicaoPagamento, setShowModalCondicaoPagamento] = useState(false);
  const [showModalSaldoEstoque, setShowModalSaldoEstoque] = useState(false);
  const [showModalConversorUnidadeMedida, setShowModalConversorUnidadeMedida] = useState(false);
  const [showModalEditarQuantidade, setShowModalEditarQuantidade] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { venda, tipoDocumento } = useSelector(state => state.pdv);
  const parametroPF021 = useSelector(selectorParametroAsBoolean('PF021'));
  const parametroPF049 = useSelector(selectorParametroAsBoolean('PF049'));

  if(!item?.itemEstoque && venda?.importado) {
    if(item?.produtoEstProduto?.saldoEstoque)
    item.itemEstoque = item?.produtoEstProduto?.saldoEstoque?.estoqueReal - item?.produtoEstProduto?.saldoEstoque?.estoqueReservado + item?.itemQuantidade;
    else if(item?.produtoEstProduto?.estSaldoestoque) {
       const getArmazem = item?.produtoEstProduto?.estSaldoestoque.filter(i => i.estArmazemId === item.produtoEstArmazemId);
       item.itemEstoque = getArmazem.reduce((total, item) => total + item.estoqueReal - item.estoqueReservado, 0);
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditarQuantidade = () => {
    handleClose();
    if (parametroPF049 || !item?.id) {
      dispatch(pdvActions.editarItemVenda(item));
    } else if (!item?.itemCancelado) {
      setShowModalEditarQuantidade(true);
    }
  };

  const produtoImposto = item?.produtoEstProduto?.estProdutoImposto?.find(i => i.intEmpresaId === empresa?.id);
  let NaoPermiteVenda;
  NaoPermiteVenda = produtoImposto?.permiteVenda === false ? true : false;

  const novoItem = { ...item };
  if(item) {
    if(venda?.editar || venda?.importado){
      novoItem.itemPercentualDesconto = ((novoItem?.itemValorDesconto / (novoItem?.itemValorUnitario * novoItem?.itemQuantidade)) * 100).toFixed(2) || 0;
    }
    novoItem.itemPercentualAcrescimo = ((novoItem?.itemValorAcrescimo / (novoItem?.itemValorUnitario * novoItem?.itemQuantidade)) * 100).toFixed(2) || 0;
  }

  return (
    <>
      <TableRow hover style={{ padding: 0 }}>
        <TableCell style={{ padding: 8 }}>
          <Grid container item>
            <Grid container item md={1} justify="center" alignItems="center">
              <Typography variant="h4" align="center">
                {index}
              </Typography>
            </Grid>
            <Grid container item md={7} wrap="nowrap">
              <Grid item zeroMinWidth>
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    textDecoration: item?.itemCancelado ? 'line-through' : 'normal'
                  }}
                >
                  {item?.produtoCodigo} {'-'} {item?.produtoDescricao}  
                </Typography>

                {!parametroPF021 && item.estoqueInsuficiente && <div style={{backgroundColor: 'red', borderRadius: '10px', padding: '1px 5px', width: 'fit-content'}}> Estoque insuficiente </div>}

                {NaoPermiteVenda && <div style={{backgroundColor: 'orange', borderRadius: '10px', padding: '1px 5px', width: 'fit-content'}}> Não permite venda! </div>}
                
                <Typography style={{ fontSize: 14 }}>
                  Qtd: {item?.itemQuantidade} {item?.produtoUnidadeComercial} {' X '}
                  Vl.Bruto: {formatCurrency(item?.itemValorUnitario)} |
                  Vl.Unit: {formatCurrency(item?.itemValorTotal / item?.itemQuantidade)} |
                  <span style={{marginTop: '10px'}}> Desc: {novoItem.itemPercentualDesconto}% - {formatCurrency(item?.itemValorDesconto)}</span> |
                  <span style={{marginTop: '10px'}}> Acrésc: {novoItem.itemPercentualAcrescimo}% - {formatCurrency(item?.itemValorAcrescimo)}</span> |
                  Est: {item?.itemEstoque} |
                  Cod.barra: {item?.produtoEan ?? ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item md={2} justify="flex-end" alignItems="center">
              <Typography align="right" style={{ fontWeight: 600 }}>
                {formatCurrency(item?.itemValorTotal)}
              </Typography>
            </Grid>
            <Grid container item md={2} justify="flex-end" alignItems="center">
              {!item?.id && (
                <Tooltip title="Excluir">
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    aria-label={'Excluir'}
                    onClick={() => dispatch(pdvActions.removerItem(item))}
                  >
                    <IconDelete />
                  </IconButton>
                </Tooltip>
              )}

              {item?.id > 0 && (
                <Tooltip title="Cancelar">
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    aria-label={'Cancelar'}
                    onClick={() => dispatch(pdvActions.cancelarItem(item))}
                  >
                    <IconCancel />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Mais ações">
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  aria-label={'Mais ações'}
                  onClick={handleClick}
                >
                  <IconMore />
                </IconButton>
              </Tooltip>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {tipoDocumento !== !item?.itemCancelado && (
                  <MenuItem onClick={() => handleEditarQuantidade()}>
                    <IconEdit />
                    <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                      Editar
                    </Mui.Typography>
                  </MenuItem>
                )}
                {isOnline && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setShowModalSaldoEstoque(true);
                    }}
                  >
                    <IconOpenInNew />
                    <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                      Saldo estoque
                    </Mui.Typography>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    handleClose();
                    setShowModalCondicaoPagamento(true);
                  }}
                >
                  <IconLocalAtm />
                  <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                    Condições de pagamento
                  </Mui.Typography>
                </MenuItem>
                {isOnline && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setShowModalConversorUnidadeMedida(true);
                    }}
                  >
                    <IconSyncAlt />
                    <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                      Converter unidade de medida
                    </Mui.Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleEditarQuantidade();
                  }}
                >
                  <EditIcon />
                  <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                    Editar produto
                  </Mui.Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      {showModalSaldoEstoque && (
        <ModalSaldoEstoqueProduto
          open={showModalSaldoEstoque}
          data={{
            produto: item?.produtoEstProduto,
            saldoEstoque: item?.saldoEstoque
          }}
          onClose={() => {
            setShowModalSaldoEstoque(false);
          }}
        />
      )}

      {showModalCondicaoPagamento && (
        <ModalCondicaoPagamentoProduto
          open={showModalCondicaoPagamento}
          data={{
            produto: item?.produtoEstProduto,
            precoVenda: item?.produtoPrecoVenda
          }}
          onClose={() => {
            setShowModalCondicaoPagamento(false);
          }}
        />
      )}

      {showModalConversorUnidadeMedida && (
        <ModalConversorUnidadeMedida
          open={showModalConversorUnidadeMedida}
          data={{
            produto: item?.produtoEstProduto,
            quantidade: item?.itemQuantidade,
            unidade: item?.produtoUnidadeComercial,
            valorUnitario: item?.itemValorUnitario
          }}
          onClose={() => {
            setShowModalConversorUnidadeMedida(false);
          }}
        />
      )}

      {showModalEditarQuantidade && (
        <ModalEditarQuantidade
          open={showModalEditarQuantidade}
          data={{
            produto: item?.produtoEstProduto,
            quantidade: item?.itemQuantidade,
            itemVenda: item
          }}
          onClose={() => {
            setShowModalEditarQuantidade(false);
          }}
        />
      )}
    </>
  );
});

export default ListaItens;
