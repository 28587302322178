import currency from 'currency.js';

export const BASE_ITEM = {
  id: null,
  _id: null,
  itemCancelado: false,
  itemSituacao: 1,
  itemValorDesconto: 0,
  itemPercentualDesconto: 0,
  itemValorAcrescimo: 0,
  itemValorFrete: 0,
  itemValorSeguro: 0,
  itemValorDespesa: 0,
  itemValorIsento: 0,
  itemValorNaoIncidente: 0,
  impostoIcmsBase: 0,
  impostoIcms: 0,
  impostoIcmsStBase: 0,
  impostoIcmsSt: 0,
  impostoIpiBase: 0,
  impostoIpi: 0,
  impostoPisBase: 0,
  impostoPis: 0,
  impostoCofinsBase: 0,
  impostoCofins: 0,
  nfeReferenciaFatDocumentosaidaItemId: null,
  nfeReferenciaCmpDocumentoentradaItemId: null,
  fatOrcamentoItemId: null,
  fatOrcamentoItemKitId: null,
  fatPedidoItemId: null,
  fatVendedor: null,
  fatVendedorId: null,
  indPedidoItem: null,
  omsModalidadeExpedicao: null,
  omsModalidadeExpedicaoId: null,
  estruturaCustomizada: false,
  wmsInstrucao: '',
  xPed: '',
  nItemPed: null,
  produtoEstArmazem: null,
  produtoEstArmazemId: null
};

export const baseMontaItem = item => {
  let prodArmazem = item?.produtoEstArmazem?.length > 0 ? item?.produtoEstArmazem[0] : item?.produtoEstProduto?.estProdutoImposto?.length > 0 
  ? item?.produtoEstProduto?.estProdutoImposto[0]?.estoqueEstArmazem : null
  let itemModalidadeExpedicao = item?.omsModalidadeExpedicao ?? null

  return {
    id: item.id,
    uuid: item.uuid,
    produtoEstProduto: {
      id: item.produtoEstProduto.id,
      codigo: item.produtoEstProduto.codigo,
      codigoAuxiliar: item.produtoEstProduto.codigoAuxiliar,
      descricao: item.produtoEstProduto.descricao,
      ean: item.produtoEstProduto.ean,
      eanTributavel: item.produtoEstProduto.eanTributavel,
      unidadeComercial: item.produtoEstProduto.unidadeComercial,
      unidadeTributavel: item.produtoEstProduto.unidadeTributavel,
      quantidadeTributavel: item.produtoEstProduto.quantidadeTributavel,
      conversorEntrada: item.produtoEstProduto.conversorEntrada,
      mercadoriaOrigem: item.produtoEstProduto.mercadoriaOrigem,
      embalagemQuantidade: item.produtoEstProduto.embalagemQuantidade,
      embalagemMultiplo: item.produtoEstProduto.embalagemMultiplo,
      estProdutoImposto: [],
      estProdutoGrupoId: item.produtoEstProduto.estProdutoGrupoId,
      saldoEstoque: item.produtoEstProduto.saldoEstoque,
      estSaldoestoque: item.produtoEstProduto.estSaldoestoque,
    },
    produtoEstProdutoGrade:
      item?.produtoEstProdutoGrade?.length > 0 ? item?.produtoEstProdutoGrade[0] ?? null : null,
    produtoEstProdutoLote: item?.produtoEstProdutoLote ?? null,
    produtoEstArmazem: prodArmazem ? {
      id                      : prodArmazem.id,
      intEmpresaId            : prodArmazem.intEmpresaId,
      descricao               : prodArmazem.descricao,
      faturamentoPermitido    : prodArmazem.faturamentoPermitido,
      transferenciaPermitida  : prodArmazem.transferenciaPermitida,
      tipo                    : prodArmazem.tipo,
    } : null,
    itemQuantidade: item?.itemQuantidade ?? 1,
    itemValorDespesa: item?.itemValorDespesa,
    itemValorFrete: item?.itemValorFrete,
    itemValorDesconto: item?.itemValorDesconto,
    itemValorAcrescimo: item?.itemValorAcrescimo,
    itemValorSeguro: item?.itemValorSeguro,
    itemValorUnitario: item?.itemValorUnitario,
    itemValorAcrescimoCondicao: item?.itemValorAcrescimoCondicao,
    itemEstoque: item?.itemEstoque ?? 0,
    itemCfop: item?.itemCfop ? item?.itemCfop : item?.produtoEstProduto?.cfopSaida,
    itemCancelado: item?.itemCancelado ?? false,
    itemSituacao: item?.itemSituacao ?? 1,
    itemTipo: item?.itemTipo ?? null,
    itensComposicaoKit: item?.itensComposicaoKit,
    nfeReferenciaFatDocumentosaidaItemId: item?.nfeReferenciaFatDocumentosaidaItemId ?? null,
    nfeReferenciaCmpDocumentoentradaItemId: item?.nfeReferenciaCmpDocumentoentradaItemId ?? null,
    fatOrcamentoItemId: item?.fatOrcamentoItemId ?? null,
    fatOrcamentoItemKitId: item?.fatOrcamentoItemKitId ?? null,
    fatPedidoItemId: item?.fatPedidoItemId ?? null,
    fatVendedor: item?.fatVendedor ?? null,
    fatVendedorId: item?.fatVendedorId ?? null,
    indPedidoItem: item?.indPedidoItem ?? null,
    omsModalidadeExpedicao: itemModalidadeExpedicao ? {
      id: itemModalidadeExpedicao?.id,
      descricao: itemModalidadeExpedicao?.descricao,
      controleEntrega: itemModalidadeExpedicao?.controleEntrega,
      desativado: itemModalidadeExpedicao?.desativado,
      permiteImprimir: itemModalidadeExpedicao?.permiteImprimir
    } : null,
    omsModalidadeExpedicaoId: item?.omsModalidadeExpedicaoId ?? null,
    estruturaCustomizada: item?.estruturaCustomizada,
    wmsInstrucao: item?.wmsInstrucao,
    xPed: item?.xPed,
    nItemPed: item?.nItemPed
  };
};

export const atualizaTotais = (itens, campo = '', verificaMovimentaFinanceiro = true, modeloVenda = null) => {
  const totalizadores = itens?.reduce(
    (totalizadores, item) => {
      if (item.itemCancelado) {
        return { ...totalizadores };
      }
      const itemValorUnitario = Number(
        currency(item?.itemValorUnitario).multiply(item.itemQuantidade)
      );
      const valorMercadoria = Number(
        currency(totalizadores?.valorMercadoria).add(itemValorUnitario ?? 0)
      );
      let valorDesconto = Number(
        currency(totalizadores?.valorDesconto).add(item?.itemValorDesconto ?? 0)
      );
      const valorAcrescimo = Number(
        currency(totalizadores?.valorAcrescimo).add(item?.itemValorAcrescimo ?? 0)
      );
      const valorFrete = Number(
        currency(totalizadores?.valorFrete).add(item?.valorFrete ?? 0).add(item?.itemValorFrete ?? 0)
      );
      let valorTotal = Number(currency(totalizadores?.valorTotal).add(item?.itemValorTotal ?? 0)) + valorFrete;

      const valorTotalFinanceiro =
        verificaMovimentaFinanceiro || item?.movimentaFinanceiro
          ? Number(currency((totalizadores.valorTotalFinanceiro += itemValorUnitario)))
          : totalizadores?.valorTotalFinanceiro;

          // if(modeloVenda !== null && modeloVenda?.valorTotal !== valorTotal && modeloVenda.valorDesconto)
          // valorTotal = modeloVenda?.valorTotal;

      return {
        valorMercadoria,
        valorDesconto,
        valorAcrescimo,
        valorFrete,
        valorTotal,
        valorTotalFinanceiro,
        valorIsento: Number(currency((totalizadores.valorIsento += item.itemValorIsento ?? 0))),
        valorNaoIncidencia: Number(
          currency((totalizadores.valorNaoIncidencia += item.itemValorNaoIncidente ?? 0))
        ),
        valorTributoIbpt: Number(
          currency((totalizadores.valorTributoIbpt += item.valorTributoIbpt ?? 0))
        ),
        impostoIcmsBase: Number(
          currency((totalizadores.impostoIcmsBase += item.impostoIcmsBase ?? 0))
        ),
        impostoIcms: Number(currency((totalizadores.impostoIcms += item.impostoIcms ?? 0))),
        impostoIcmsStBase: Number(
          currency((totalizadores.impostoIcmsStBase += item.impostoIcmsStBase ?? 0))
        ),
        impostoIcmsSt: Number(currency((totalizadores.impostoIcmsSt += item.impostoIcmsSt ?? 0))),
        impostoIpiBase: Number(
          currency((totalizadores.impostoIpiBase += item.impostoIpiBase ?? 0))
        ),
        impostoIpi: Number(currency((totalizadores.impostoIpi += item.impostoIpi ?? 0))),
        impostoPisBase: Number(
          currency((totalizadores.impostoPisBase += item.impostoPisBase ?? 0))
        ),
        impostoPis: Number(currency((totalizadores.impostoPis += item.impostoPis ?? 0))),
        impostoCofinsBase: Number(
          currency((totalizadores.impostoCofinsBase += item.impostoCofinsBase ?? 0))
        ),
        impostoCofins: Number(currency((totalizadores.impostoCofins += item.impostoCofins ?? 0))),
        impostoIcmsDesonerado: Number(
          currency((totalizadores.impostoIcmsDesonerado += item.impostoIcmsDesoneracao ?? 0))
        )
      };
    },
    {
      valorMercadoria: 0,
      valorDesconto: 0,
      valorAcrescimo: 0,
      valorFrete: 0,
      valorSeguro: 0,
      valorDespesa: 0,
      valorTotal: 0,
      valorTotalFinanceiro: 0
    }
  );

  if (campo !== 'valorAcrescimo') {
    Object.assign(totalizadores, {
      percentualAcrescimo: Number(
        currency((totalizadores?.valorAcrescimo / totalizadores?.valorMercadoria) * 100, {
          precision: 2
        })
      )
    });
  }

  if (campo !== 'valorFrete') {
    Object.assign(totalizadores, {
      percentualFrete: Number(
        currency((totalizadores?.valorFrete / totalizadores?.valorMercadoria) * 100, {
          precision: 2
        })
      )
    });
  }

  const percentualCalculo = (totalizadores?.valorDesconto / totalizadores?.valorMercadoria) * 100;

  if (campo !== 'valorDesconto') {
    Object.assign(totalizadores, {
      percentualDesconto: percentualCalculo
    });
  }

  return {
    ...totalizadores
  };
};

export const atualizaValoresItens = (venda) => {
  let totalDescontoItens = 0;
  let totalAcrescimoItens = 0;
  let totalFreteItens = 0;
  const itens = [...venda?.itens];

  const getValorMercadoria = (campo = '') => {
    return venda?.valorMercadoria;
    const valorMercadoria = itens?.reduce((valorMercadoria, item) => {
      const itemValorUnitario = Number(
        currency(item?.itemValorUnitario).multiply(item.itemQuantidade)
      );

      if (campo === 'desconto') {
        return (valorMercadoria += item?.itemPercentualDesconto === 0 ? itemValorUnitario : 0);
      } else if (campo === 'acrescimo') {
        return (valorMercadoria += item?.itemPercentualAcrescimo === 0 ? itemValorUnitario : 0);
      } else {
        return (valorMercadoria += itemValorUnitario);
      }
    }, 0);

    return valorMercadoria;
  };

  const getValorDesconto = () => {
    return venda?.valorDesconto;

    const valorDescontoGeral = itens?.reduce((valorDesconto, item) => {
      return (valorDesconto += item?.itemPercentualDesconto === 0 ? item?.itemValorDesconto : 0);
    }, 0);
    const valorDescontoAplicadoPorItem = itens?.reduce((valorDesconto, item) => {
      return (valorDesconto += item?.itemPercentualDesconto > 0 ? item?.itemValorDesconto : 0);
    }, 0);

    return valorDescontoGeral === 0
      ? venda?.valorDesconto
      : valorDescontoGeral - valorDescontoAplicadoPorItem;
  };

  const getValorAcrescimo = () => {
    return venda?.valorAcrescimo;

    const valorAcrescimoManual = itens?.reduce((valorAcrescimo, item) => {
      return (valorAcrescimo += item?.itemPercentualAcrescimo > 0 ? item?.itemValorAcrescimo : 0);
    }, 0);

    return venda?.valorAcrescimo - valorAcrescimoManual;
  };

  return itens?.length > 0
    ? [
        ...itens?.map((item, index) => {
          if (item?.itemCancelado) {
            return {
              ...item,
              itemValorDesconto: 0,
              itemValorAcrescimo: 0,
              itemValorFrete: 0,
              itemValorSeguro: 0,
              itemValorDespesa: 0
            };
          }

          //Desconto
          const hasDescontoNoItem = item?.itemPercentualDesconto > 0;
          const valorBaseDesconto = venda?.valorDesconto ?? 0;
          let valorDesconto = !hasDescontoNoItem
            ? (venda.percentualDesconto / 100) * (item?.itemValorUnitario * item?.itemQuantidade)
            : item?.itemValorDesconto;
          valorDesconto = Math.round((Number(valorDesconto) + Number.EPSILON) * 100) / 100;
          totalDescontoItens = !hasDescontoNoItem
            ? Number(currency(totalDescontoItens).add(valorDesconto))
            : totalDescontoItens;

          const restoValorDesconto = !hasDescontoNoItem
            ? Number(currency(valorBaseDesconto ?? 0).subtract(totalDescontoItens))
            : 0;

          //Acrescimo
          const hasAcrescimoNoItem = item?.itemPercentualAcrescimo > 0;
          const hasFreteNoItem = item?.itemPercentualFrete > 0;
          const valorBaseAcrescimo = venda?.valorAcrescimo ?? 0;

          venda.itemPercentualFrete = venda.itemPercentualFrete ? venda.itemPercentualFrete : 0;

          let valorAcrescimo = !hasAcrescimoNoItem
            ? (venda.percentualAcrescimo / 100) * (item?.itemValorUnitario * item?.itemQuantidade)
            : item?.itemValorAcrescimo;
            
          valorAcrescimo = Math.round((Number(valorAcrescimo) + Number.EPSILON) * 100) / 100;
          totalAcrescimoItens = !hasAcrescimoNoItem
              ? Number(currency(totalAcrescimoItens).add(valorAcrescimo))
              : totalAcrescimoItens;

            const restoValorAcrescimo = !hasAcrescimoNoItem
            ? Number(currency(valorBaseAcrescimo ?? 0).subtract(totalAcrescimoItens))
            : 0;

            let valorFrete = (venda.percentualFrete / 100) * (item?.itemValorUnitario * item?.itemQuantidade);

            valorFrete = Math.round((Number(valorFrete) + Number.EPSILON) * 100) / 100;
            totalFreteItens = !hasFreteNoItem ? Number(currency(totalFreteItens).add(valorFrete)) : totalFreteItens;

          const itemAtualizado = {
            ...item,
            itemValorDesconto:
              venda?.itens?.length - 1 === index
                ? Number(currency(valorDesconto ?? 0).add(restoValorDesconto))
                : valorDesconto,
            itemValorAcrescimo:
              venda?.itens?.length - 1 === index
                ? Number(currency(valorAcrescimo ?? 0).add(restoValorAcrescimo))
                : valorAcrescimo,
            itemValorFrete: venda?.itens?.length - 1 === index ? Number(currency(valorFrete ?? 0)) : valorFrete,
            itemValorSeguro: Number(
              currency((venda?.valorSeguro ?? 0, { precision: 2 }))
            ),
            itemValorDespesa: Number(
              currency((venda?.valorDespesa ?? 0, { precision: 2 }))
            )
          };

          return {
            ...itemAtualizado,
            itemValorTotal: calculaValorTotalItem(itemAtualizado)
          };
        })
      ]
    : [];
};

export const calculaValorTotalItem = item => {
  const total = Number(
    currency(item?.itemValorUnitario * item?.itemQuantidade, {
      precision: 2
    })
      .add(item?.itemValorAcrescimo ?? 0)
      .add(item?.itemValorFrete ?? 0)
      .add(item?.impostoIcmsSt ?? 0)
      .add(item?.impostoIpi ?? 0)
      .subtract(item?.itemValorDesconto ?? 0)
  );

  return total;
};
