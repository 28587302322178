import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import * as MuiIcons from '@material-ui/icons';

import { formatDatetime } from '~/utils/format';
import { getApplicationIsOnline } from "~/store/modules/app/app-selectors"
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import ModalSaldoEstoqueProduto from '~/pages/TelaVenda/modal/ModalSaldoEstoqueProduto';
import ModalSaldoEstoqueTransferencia from '~/pages/TelaVenda/modal/ModalSaldoEstoqueTransferencia';
import { Container } from './styles';

const EstoqueProduto = React.memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { itemVenda, loadingProduto } = useSelector(state => state.pdv);
  const isOnline = useSelector(getApplicationIsOnline());
  const [showModalSaldoEstoque, setShowModalSaldoEstoque] = useState(false);
  const [showModalTransferenciaFilial, setShowModalTransferenciaFilial] = useState(false);

  return (
    <Box>
      <Typography variant="caption" gutterBottom align="left">
        ESTOQUE
      </Typography>
      <Container>
        <Grid container item justify="space-between" alignItems="center">
          <Grid container item md={4} alignItems="center">
            <MuiIcons.History />
            <Box pl={2}>
              <Typography variant="caption">
                {`  Ult. Atualização ${itemVenda?.saldoEstoque ? formatDatetime(new Date()) : '-'}`}
              </Typography>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography variant="caption" align="center">
              Disponível
            </Typography>
            <Typography align="center">
              <b style={{ color: '#09A848' }}>
                {itemVenda?.produtoEstArmazem?.estoqueDisponivel ?? '-'}
              </b>
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography variant="caption" align="center">
              Real
            </Typography>
            <Typography align="center">
              <b> {itemVenda?.produtoEstArmazem?.estoqueReal ?? '-'}</b>
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography variant="caption" align="center">
              Reservado
            </Typography>
            <Typography align="center">
              <b> {itemVenda?.produtoEstArmazem?.estoqueReservado ?? '-'}</b>
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography variant="caption" align="center">
              Futuro
            </Typography>
            <Typography align="center">
              <b> {itemVenda?.produtoEstArmazem?.estoqueFuturo ?? '-'}</b>
            </Typography>
          </Grid>
          {isOnline && (
            <Tooltip title="Mostrar Saldo de Estoque">
              <Fab
                size="small"
                disabled={!itemVenda?.produtoEstProduto || loadingProduto}
                aria-label="add"
                style={{
                  backgroundColor:
                    !itemVenda?.produtoEstProduto || loadingProduto
                      ? theme.palette.background.default
                      : '#FFF',
                  boxShadow: theme.shadows[6]
                }}
                onClick={() => setShowModalSaldoEstoque(true)}
              >
                <MuiIcons.OpenInNew />
              </Fab>
            </Tooltip>
          )}
          {isOnline && (
            <Tooltip title="Estoque em outras filiais">
              <Fab
                size="small"
                disabled={!itemVenda?.produtoEstProduto || loadingProduto}
                aria-label="add"
                style={{
                  backgroundColor:
                    !itemVenda?.produtoEstProduto || loadingProduto
                      ? theme.palette.background.default
                      : '#FFF',
                  boxShadow: theme.shadows[6]
                }}
                onClick={() => setShowModalTransferenciaFilial(true)}
              >
                <MuiIcons.ImportExport />
              </Fab>
            </Tooltip>
          )}
        </Grid>
      </Container>

      {showModalSaldoEstoque && (
        <ModalSaldoEstoqueProduto
          open={showModalSaldoEstoque}
          data={{
            produto: itemVenda?.produtoEstProduto,
            saldoEstoque: itemVenda?.saldoEstoque
          }}
          onClose={() => {
            setShowModalSaldoEstoque(false);
          }}
        />
      )}

      {showModalTransferenciaFilial && (
        <ModalSaldoEstoqueTransferencia
          open={showModalTransferenciaFilial}
          data={{
            produto: itemVenda?.produtoEstProduto,
            saldoEstoque: itemVenda?.saldoEstoque
          }}
          onClose={payload => {
            dispatch(
              pdvActions.setTransferenciaItem({
                intEmpresaSolicitadaId: payload?.empresa?.id,
                estArmazemSolicitadoId: payload?.saldoEstoque?.estArmazem?.id
              })
            );
            setShowModalTransferenciaFilial(false);
          }}
        />
      )}
    </Box>
  );
});

export default EstoqueProduto;
