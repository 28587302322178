import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, useTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Lottie from 'react-lottie';

import toast from '~/services/toast';
import { MEIO_PAGAMENTO, TIPO_DOCUMENTO, API_BASE_URL } from '~/constants';
import { formatCurrency, formatDate } from '~/utils/format';
import { getParametroAsBoolean, getParametroAsInteger } from '~/utils/parametros';
import { selectorFechamento } from '~/store/modules/pdv/pdv-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import cashAnimation from '~/assets/animations/money.json';
import creditCardAnimation from '~/assets/animations/credit-card.json';

import ModalCadastroCheque from '../ModalCheque';
import PagamentoParcela from '../ModalConsultaPagamento/components/PagamentoParcela';
import ParcelaPagamentoTableRow from '../../components/ParcelaPagamentoTableRow';
import { CurrencyInputCustom } from '~/components/CustomInput';
import { Header } from './styles';
import axios from 'axios';

const ModalConfirmaPagamento = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { parametros } = useSelector(state => state.auth);
  const {
    venda,
    itemPagamento,
    tipoDocumento,
    selects,
    showModalConfirmaPagamento,
    showModalCadastroCheque
  } = useSelector(state => state.pdv);
  const { permiteFechamento, valorSaldoPagar, valorPago, parcelas } = useSelector(
    selectorFechamento(itemPagamento?.parcelas ?? [])
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [valorDescontoAnterior, setValorDescontoAnterior] = useState(0);
  const [valorAcrescimoAnterior, setValorAcrescimoAnterior] = useState(0);

  const condicaoPagamentoObrigatoria = getParametroAsBoolean(parametros, 'PF018') ?? false;
  const vendedorObrigatorio = getParametroAsBoolean(parametros, 'PF024') ?? false;

  const [bandeiras, setBandeiras] = useState([]);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const changeDesconto = e => {
    const desconto = e.floatvalue;
    dispatch(pdvActions.atualizarDesconto(desconto));
  };

  const changeDescontoPercentual = e => {
    const desconto = e.floatvalue;
    dispatch(pdvActions.atualizarDescontoPercentual(desconto));
  };

  const changeAcrescimo = e => {
    const acrescimo = e.floatvalue;
    dispatch(pdvActions.atualizarAcrescimo(acrescimo));
  };

  const changeNumeroDocumento = (documentoNumero, entrada) => {
    const parcelasCartao = entrada
      ? [parcelas?.find(x => x.finMeiopagamentoId === itemPagamento?.meioPagamentoEntrada?.id)]
      : parcelas?.filter(x => x.finMeiopagamentoId === itemPagamento?.meioPagamento?.id);

    for (const parcela of parcelasCartao) {
      dispatch(pdvActions.atualizarParcelaFechamento({ ...parcela, documentoNumero }));
    }
    dispatch(pdvActions.atualizarNumeroDocumentoCartao(documentoNumero, entrada));
  };

  const changeBandeiraCartao = (fatCartaobandeira, entrada) => {
    const parcelasCartao = entrada
      ? [parcelas?.find(x => x.finMeiopagamentoId === itemPagamento?.meioPagamentoEntrada?.id)]
      : parcelas?.filter(x => x.finMeiopagamentoId === itemPagamento?.meioPagamento?.id);

    for (const parcela of parcelasCartao) {
      dispatch(pdvActions.atualizarParcelaFechamento({ ...parcela, fatCartaobandeira }));
    }
    dispatch(pdvActions.atualizarBandeiraCartao(fatCartaobandeira, entrada));
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConfirmaPagamento({ show: false }));
  };

  const handleSubmit = () => {

    if(itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CHEQUE &&
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && itemPagamento.parcelas.length > 0) {
        let parcelaSemCheque = false;
        parcelaSemCheque = itemPagamento.parcelas.some(parcela => {
          if(!parcela.cheque) return true;
        })
        if(parcelaSemCheque) {
          toast.warning('Informe os dados do cheque da parcela!');
          return;
        }
      }

      
    if (
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
      (itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
        itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO ||
        itemPagamento?.meioPagamentoEntrada?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
        itemPagamento?.meioPagamentoEntrada?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO)
    ) {
      if (
        itemPagamento?.meioPagamento &&
        itemPagamento?.meioPagamento?.tef &&
        itemPagamento?.meioPagamento?.tefModalidade === null &&
        itemPagamento?.meioPagamento?.documentoNumero === ''
      ) {
        toast.warning('Informar o número do documento (POS)');
        return;
      }

      if (
        itemPagamento?.meioPagamento &&
        itemPagamento?.meioPagamento?.tef &&
        !itemPagamento?.meioPagamento?.fatCartaobandeira
      ) {
        toast.warning('Informar bandeira para pagamento (parcelas) com cartão crédito/débito');
        return;
      }

      if (
        itemPagamento?.meioPagamentoEntrada &&
        itemPagamento?.meioPagamentoEntrada?.tef &&
        itemPagamento?.meioPagamentoEntrada?.tefModalidade === null &&
        itemPagamento?.meioPagamentoEntrada?.documentoNumero === ''
      ) {
        toast.warning('Informar o número do documento (POS)');
        return;
      }

      if (
        itemPagamento?.meioPagamentoEntrada &&
        itemPagamento?.meioPagamentoEntrada?.tef &&
        !itemPagamento?.meioPagamentoEntrada?.fatCartaobandeira
      ) {
        toast.warning('Informar bandeira para pagamento (entrada) com cartão crédito/débito');
        return;
      }
    }
    dispatch(pdvActions.setShowModalConfirmaPagamento({ show: false }));
    // dispatch(pdvActions.fecharVenda());
    dispatch(pdvActions.adicionarPagamento());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(`${API_BASE_URL}/v1/consulta/cartaobandeira`);
        const data = await response.data
        if (Array.isArray(data)) {
          sessionStorage.setItem('bandeiras', JSON.stringify(data));
          setBandeiras(data);
        } else {
          setBandeiras([]);
        }
      } catch (error) {
        console.error('Falha ao buscar dados da API:', error);
        setBandeiras([]);
      }
    };
  
    const bandeirasStorage = sessionStorage.getItem('bandeiras');
    if (bandeirasStorage) {
      try {
        const bandeirasParsed = JSON.parse(bandeirasStorage);
        if (Array.isArray(bandeirasParsed)) {
          setBandeiras(bandeirasParsed);
        } else {
          fetchData();
        }
      } catch (error) {
        console.error('Erro ao parsear dados do sessionStorage:', error);
        fetchData();
      }
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    dispatch(pdvActions.iniciaPagamento());

    setValorDescontoAnterior(venda?.valorDesconto ?? 0);
    setValorAcrescimoAnterior(venda?.valorAcrescimo ?? 0);
  }, []);

  return (
    <>
      <Mui.Dialog
        open={showModalConfirmaPagamento}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
        style={{zIndex: 99}}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  FECHAMENTO VENDA
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
          <Header>
            <Mui.Box ml={1}>
              <Mui.Typography variant="caption">Forma pagamento</Mui.Typography>
              <Mui.Typography variant="h5">
                {itemPagamento?.meioPagamento?.descricao} (
                {itemPagamento?.condicaoPagamento?.descricao})
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box textAlign="right">
              <Mui.Typography variant="caption" align="right">
                Saldo a pagar
              </Mui.Typography>
              <Mui.Typography
                variant="h3"
                align="right"
                style={{ textTransform: 'uppercase', fontWeight: 600 }}
              >
                {formatCurrency(venda?.valorTotal)}
              </Mui.Typography>
            </Mui.Box>
          </Header>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ maxWidth: 640 }}>
          <Mui.Box p={2} mb={2}>
            <Mui.Box mb={2}>
              <Mui.Paper>
                <Mui.Box p={2}>
                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item md={4}>
                      <Mui.Typography align="left" variant="caption">
                        Desconto (R$)
                      </Mui.Typography>
                      <Mui.TextField
                        value={venda?.valorDesconto ?? 0}
                        onChange={changeDesconto}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">R$</Mui.InputAdornment>
                          ),
                          inputComponent: CurrencyInputCustom,
                          style: {
                            height: 54
                          }
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={2}>
                      <Mui.Typography align="left" variant="caption">
                        Desc.(%)
                      </Mui.Typography>
                      <Mui.TextField
                        value={venda?.percentualDesconto ?? 0}
                        onChange={changeDescontoPercentual}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          inputComponent: CurrencyInputCustom,
                          style: {
                            height: 54
                          }
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <Mui.Typography align="left" variant="caption">
                        Acréscimo (R$)
                      </Mui.Typography>
                      <Mui.TextField
                        value={venda?.valorAcrescimo ?? 0}
                        onChange={changeAcrescimo}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">R$</Mui.InputAdornment>
                          ),
                          inputComponent: CurrencyInputCustom,
                          style: {
                            height: 54
                          }
                        }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Box>
              </Mui.Paper>
            </Mui.Box>

            {itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO && (
              <Mui.Paper>
                <Mui.Box p={2}>
                  <Mui.Typography variant="caption" align="left">
                    {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                      ? 'Valor Recebido (R$)'
                      : 'Valor total (R$)'}
                  </Mui.Typography>
                  <Mui.TextField
                    variant="outlined"
                    fullWidth
                    value={
                      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                        ? itemPagamento?.valorRecebido
                        : venda.valorTotal
                    }
                    disabled={tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA}
                    onChange={({ floatvalue }) => {
                      dispatch(pdvActions.atualizarValorRecebido(floatvalue));
                    }}
                    InputProps={{
                      startAdornment: <Mui.InputAdornment position="start">R$</Mui.InputAdornment>,
                      inputComponent: CurrencyInputCustom,
                      style: {
                        height: 54
                      }
                    }}
                  />
                </Mui.Box>
                {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && (
                  <>
                    <Mui.Box p={2} display="flex" flexDirection="column" alignItems="center">
                      <Mui.Typography variant="caption">Troco (R$)</Mui.Typography>

                      <Mui.Typography variant="h1">
                        {formatCurrency(itemPagamento?.valorTroco)}
                      </Mui.Typography>
                    </Mui.Box>
                  </>
                )}
              </Mui.Paper>
            )}
            <Mui.Paper>
              {itemPagamento?.condicaoPagamento?.entradaExigida && (
                <Mui.Box p={2}>
                  <Mui.Typography variant="caption">Meio de pagamento da entrada</Mui.Typography>

                  <Mui.Grid item md={12} style={{ marginBottom: 16 }}>
                    {itemPagamento?.condicaoPagamento?.meiosPagamentoEntrada?.map(
                      (meioPagamento, index) => {
                        const entradaSelecionada =
                          itemPagamento?.meioPagamentoEntrada?.id === meioPagamento?.id;

                        return (
                          <>
                            <Mui.Button
                              key={`index-${index}`}
                              variant="contained"
                              color={entradaSelecionada ? 'primary' : 'default'}
                              style={{ margin: 4 }}
                              onClick={() =>
                                dispatch(pdvActions.selecionarMeiopagamentoEntrada(meioPagamento))
                              }
                            >
                              {meioPagamento?.descricao}
                            </Mui.Button>
                          </>
                        );
                      }
                    )}
                  </Mui.Grid>

                  <Mui.Grid container spacing={2}>
                    <Mui.Grid item md={6}>
                      <Mui.Box flex flexDirection="row">
                        <Mui.Box mb={2}>
                          <Mui.Typography variant="caption" align="left">
                            Valor entrada (R$)
                          </Mui.Typography>
                          <Mui.TextField
                            variant="outlined"
                            fullWidth
                            value={itemPagamento?.valorEntrada}
                            onChange={({ floatvalue }) => {
                              dispatch(pdvActions.atualizarValorEntrada(floatvalue));
                            }}
                            InputProps={{
                              inputComponent: CurrencyInputCustom,
                              style: {
                                height: 54
                              }
                            }}
                          />
                        </Mui.Box>
                      </Mui.Box>
                    </Mui.Grid>
                  </Mui.Grid>

                  {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
                    itemPagamento?.meioPagamentoEntrada?.tef && (
                      <Mui.Grid container spacing={2}>
                        {itemPagamento?.meioPagamentoEntrada?.tefModalidade === null && (
                          <Mui.Grid item md={6}>
                            <Mui.Typography align="left" variant="caption">
                              Número do Documento (entrada)
                            </Mui.Typography>
                            <Mui.TextField
                              value={itemPagamento?.meioPagamentoEntrada?.documentoNumero ?? ''}
                              onChange={e => changeNumeroDocumento(e.target.value, true)}
                              variant="outlined"
                              fullWidth
                            />
                          </Mui.Grid>
                        )}

                        <Mui.Grid
                          item
                          md={itemPagamento?.meioPagamentoEntrada?.tefModalidade === null ? 6 : 12}
                        >
                          <Mui.Typography align="left" variant="caption">
                            Bandeira do cartão (entrada)
                          </Mui.Typography>
                          <Mui.Select
                            variant="outlined"
                            value={itemPagamento?.meioPagamentoEntrada?.fatCartaobandeira ?? null}
                            onChange={e => changeBandeiraCartao(e.target.value, true)}
                            fullWidth
                          >
                            {selects?.bandeiras
                              ?.filter(x =>
                                x.operacao ==
                                (itemPagamento?.meioPagamentoEntrada?.nfePagamento ===
                                  MEIO_PAGAMENTO.CARTAO_CREDITO)
                                  ? 0
                                  : 1
                              )
                              ?.map((x, index) => (
                                <Mui.MenuItem key={index} value={x}>
                                  {x.descricao}
                                </Mui.MenuItem>
                              ))}
                          </Mui.Select>
                        </Mui.Grid>
                      </Mui.Grid>
                    )}
                </Mui.Box>
              )}

              <Mui.Divider />
              {(itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO) && (
                <>
                  {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
                    itemPagamento?.meioPagamento?.tef && (
                      <Mui.Box p={2}>
                        <Mui.Grid container spacing={2}>
                          {itemPagamento?.meioPagamento?.tefModalidade === null && (
                            <Mui.Grid item md={6}>
                              <Mui.Typography align="left" variant="caption">
                                Número de Autorização (parcelas)
                              </Mui.Typography>
                              <Mui.TextField
                                value={itemPagamento?.meioPagamento?.cartaoAutorizacao ?? ''}
                                onChange={e => changeNumeroDocumento(e.target.value, false)}
                                variant="outlined"
                                fullWidth
                              />
                            </Mui.Grid>
                          )}

                          <Mui.Grid item md={itemPagamento?.meioPagamento?.tefModalidade === null ? 6 : 12}>
                            <Mui.Typography align="left" variant="caption">
                              Bandeira do cartão (parcelas)
                            </Mui.Typography>
                            <Mui.Select
                              variant="outlined"
                              value={itemPagamento?.meioPagamento?.fatCartaobandeira ?? null}
                              onChange={e => changeBandeiraCartao(e.target.value, false)}
                              fullWidth
                            >
                              {bandeiras
                                ?.filter(x =>
                                  x.operacao ==
                                  (itemPagamento?.meioPagamento?.nfePagamento ===
                                    MEIO_PAGAMENTO.CARTAO_CREDITO)
                                    ? 0
                                    : 1
                                  )
                                  ?.map((x, index) => (
                                  <Mui.MenuItem key={index} value={x}>
                                    {x.descricao}
                                  </Mui.MenuItem>
                                ))
                              }
                            </Mui.Select>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Box>
                    )}

                  <Mui.Divider />
                  <Mui.Box p={2} display="flex" flexDirection="column" alignItems="center">
                    <Mui.Typography variant="caption">Valor recebido (R$)</Mui.Typography>
                    <Mui.Typography variant="h1">
                      {formatCurrency(itemPagamento?.valorRecebido)}
                    </Mui.Typography>
                    <PagamentoParcela show={true} selected={true} />
                  </Mui.Box>
                </>
              )}
            </Mui.Paper>
            {parcelas?.length > 0 &&
              !(
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO ||
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
                itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO
              ) && (
                <Mui.TableContainer component={Paper}>
                  <Mui.Table aria-label="spanning table">
                    <Mui.TableHead>
                      <Mui.TableRow>
                        <Mui.TableCell align="left">Parcela</Mui.TableCell>
                        <Mui.TableCell align="center">Data</Mui.TableCell>
                        <Mui.TableCell align="right">Valor</Mui.TableCell>
                        <Mui.TableCell align="center">#</Mui.TableCell>
                      </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                      {parcelas?.map((parcela, parcelaIndex) => (
                        <ParcelaPagamentoTableRow key={parcelaIndex} parcela={parcela} />
                        // <Mui.TableRow key={`îndex-${index}`}>
                        //   <Mui.TableCell align="left">
                        //     <>
                        //       {itemPagamento?.condicaoPagamento?.entradaExigida && index === 0
                        //         ? 'Entrada'
                        //         : `${parcela?.parcelaNumero}/${itemPagamento?.condicaoPagamento?.quantidadeParcela}`}
                        //     </>
                        //   </Mui.TableCell>
                        //   <Mui.TableCell align="center">
                        //     <Mui.Typography>
                        //       {formatDate(new Date(parcela?.parcelaVencimento))}
                        //     </Mui.Typography>
                        //   </Mui.TableCell>
                        //   <Mui.TableCell align="right">
                        //     <Mui.Typography>
                        //       <b>{formatCurrency(parcela?.parcelaValor)}</b>
                        //     </Mui.Typography>
                        //   </Mui.TableCell>
                        //   {itemPagamento?.meioPagamento?.nfePagamento === MEIO_PAGAMENTO.CHEQUE && (
                        //     <Mui.TableCell align="right">
                        //       <Mui.Tooltip title="Mais ações">
                        //         <Mui.IconButton
                        //           aria-controls="simple-menu"
                        //           aria-haspopup="true"
                        //           aria-label={'Mais ações'}
                        //           onClick={e => handleOpenMenu(e)}
                        //         >
                        //           <MuiIcons.MoreVert />
                        //         </Mui.IconButton>
                        //       </Mui.Tooltip>
                        //       <Mui.Menu
                        //         id="simple-menu"
                        //         anchorEl={anchorEl}
                        //         keepMounted
                        //         open={Boolean(anchorEl)}
                        //         onClose={handleCloseMenu}
                        //       >
                        //         <Mui.MenuItem
                        //           onClick={() => {
                        //             setShowModalCheque({
                        //               show: true,
                        //               data: parcela?.cheque
                        //                 ? { ...parcela?.cheque, chequeValor: parcela?.parcelaValor }
                        //                 : {
                        //                     chequeValor: parcela?.parcelaValor,
                        //                     chequeVencimento: parcela?.parcelaVencimento
                        //                   }
                        //             })
                        //           }}
                        //         >
                        //           <MuiIcons.Add fontSize="small" />
                        //           <Mui.Typography style={{ marginLeft: 12 }}>
                        //             Adicionar Cheque
                        //           </Mui.Typography>
                        //         </Mui.MenuItem>

                        //         <Mui.MenuItem
                        //           onClick={() => {
                        //             setItemPagamento(state => {
                        //               const parcelas = [state.parcelas];
                        //               parcelas[parcelaIndex].cheque = null;
                        //               return parcelas;
                        //             })
                        //           }}
                        //         >
                        //           <MuiIcons.Remove fontSize="small" />
                        //           <Mui.Typography style={{ marginLeft: 12 }}>
                        //             Remover Cheque
                        //           </Mui.Typography>
                        //         </Mui.MenuItem>
                        //       </Mui.Menu>
                        //     </Mui.TableCell>
                        //   )}
                        // </Mui.TableRow>
                      ))}
                    </Mui.TableBody>
                  </Mui.Table>
                </Mui.TableContainer>
              )}
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          {!permiteFechamento ||
          (valorSaldoPagar !== 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO) ? (
            <Mui.Button
              size="large"
              onClick={() => {
                dispatch(pdvActions.iniciaPagamento());
              }}
              variant="contained"
            >
              <MuiIcons.Refresh /> <span style={{ paddingLeft: 8 }}>CALCULAR PARCELAS</span>
            </Mui.Button>
          ) : (
            <Mui.Button
              size="large"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={
                !permiteFechamento ||
                (valorSaldoPagar !== 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO)
              }
            >
              <MuiIcons.CheckCircle /> <span style={{ paddingLeft: 8 }}>CONFIRMAR PAGAMENTO</span>
            </Mui.Button>
          )}

          <Mui.Button startIcon={<MuiIcons.Close />} size="large" onClick={handleClose}>
            CANCELAR (ESC)
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      {showModalCadastroCheque?.show && (
        <ModalCadastroCheque
          callback={data => {
            dispatch(pdvActions.setShowModalCadastroCheque({ show: false }));
            dispatch(pdvActions.adicionarChequeParcela(itemEdicao.uuid, data));
          }}
        />
      )}
    </>
  );
};

export default ModalConfirmaPagamento;
