import React, { createRef, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { alpha, makeStyles } from '@material-ui/core';
import { HotKeys } from 'react-hotkeys';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as Mui from '@material-ui/core';

import TopBar from './TopBar';

import { getParametroAsBoolean } from '~/utils/parametros';
import { SET_FOCUS_CONSULTA_PRODUTO } from '~/store/modules/pdv/pdv-constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import FooterBar from '~/components/FooterBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    width: '100%',

    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
    // paddingBottom: 24
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const PdvLayout = ({ route }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { venda, matrizCondicaoPagamento, itemPagamento } = useSelector(state => state.pdv);
  const { mensagemTef, terminal } = useSelector(state => state.app);
  const { parametros } = useSelector(state => state.auth);

  const condicaoPagamentoObrigatoria = getParametroAsBoolean(parametros, 'PF018') ?? false;

  const keyMap = {
    IDENTIFICAR_CLIENTE: {
      name: 'Identificar cliente (CTRL+I)',
      sequences: ['ctrl+i', 'command+i']
    },
    CONSULTA_OPERACAO: {
      name: 'Consulta operação (CTRL+O)',
      sequences: ['ctrl+o', 'command+o']
    },
    CONSULTA_VENDEDOR: {
      name: 'Consulta vendedor (CTRL+S)',
      sequences: ['ctrl+s', 'command+s']
    },
    CONSULTA_CLIENTE: {
      name: 'Consulta cliente (F2)',
      sequences: ['f2']
    },
    FOCO_CONSULTA_PRODUTO: {
      name: 'Foco consulta de produto (F3)',
      sequences: ['f3']
    },
    CONSULTA_PRODUTO: {
      name: 'Consulta produto (F4)',
      sequences: ['f4']
    },
    CONSULTA_RAPIDA_PRODUTO: {
      name: 'Consulta rápida de produto (CTRL+F4)',
      sequences: ['ctrl+f4', 'command+f4']
    },
    CONSULTA_PAGAMENTO1: {
      name: `Pagar com ${matrizCondicaoPagamento[0]?.descricao} (F5)`,
      sequences: ['f5']
    },
    CONSULTA_PAGAMENTO2: {
      name: `Pagar com ${matrizCondicaoPagamento[1]?.descricao} (F6)`,
      sequences: ['f6']
    },
    CONSULTA_PAGAMENTO3: {
      name: `Pagar com ${matrizCondicaoPagamento[2]?.descricao} (F7)`,
      sequences: ['f7']
    },
    CONSULTA_PAGAMENTO4: {
      name: `Pagar com ${matrizCondicaoPagamento[3]?.descricao} (F8)`,
      sequences: ['f8']
    },
    CONSULTA_PAGAMENTO5: {
      name: `Pagar com ${matrizCondicaoPagamento[4]?.descricao} (F9)`,
      sequences: ['f9']
    },
    CONSULTA_PAGAMENTO_OUTROS: {
      name: 'Pagar com outro pagamento (F10)',
      sequences: ['f10']
    },

    FECHAR_VENDA: {
      name: 'Fechar venda (F12)',
      sequences: ['f12']
    }
  };

  const handlers = {
    CONSULTA_CLIENTE: () => {
      dispatch(pdvActions.setShowModalConsultaCliente({ show: true }));
    },
    FOCO_CONSULTA_PRODUTO: () => {
      dispatch({ type: SET_FOCUS_CONSULTA_PRODUTO, focus: true });
    },
    CONSULTA_PRODUTO: () => {
      dispatch(pdvActions.setShowModalConsultaProduto({ show: true }));
    },
    CONSULTA_RAPIDA_PRODUTO: () => {
      dispatch(pdvActions.setShowModalConsultaRapidaProduto({ show: true }));
    },
    CONSULTA_OPERACAO: () => {
      dispatch(pdvActions.setShowModalConsultaOperacao({ show: true }));
    },
    CONSULTA_VENDEDOR: () => {
      dispatch(pdvActions.setShowModalConsultaVendedor({ show: true }));
    },
    IDENTIFICAR_CLIENTE: () => {
      dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
    },
    CONSULTA_PAGAMENTO1: () => handlePagamento(0),
    CONSULTA_PAGAMENTO2: () => handlePagamento(1),
    CONSULTA_PAGAMENTO3: () => handlePagamento(2),
    CONSULTA_PAGAMENTO4: () => handlePagamento(3),
    CONSULTA_PAGAMENTO5: () => handlePagamento(4),
    CONSULTA_PAGAMENTO_OUTROS: () => {
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: true,
          pagamentoSelecionado: null
        })
      );
    },

    FECHAR_VENDA: () => handleFecharVenda()
  };

  const handlePagamento = index => {
    const meioPagamento = matrizCondicaoPagamento[index];
    const isSelected = itemPagamento?.meioPagamento?.id === meioPagamento?.id;

    if (condicaoPagamentoObrigatoria) {
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: true,
          pagamentoSelecionado: isSelected
            ? { ...itemPagamento }
            : {
                meioPagamento,
                condicoes: meioPagamento?.condicoes,
                condicaoPagamento:
                  meioPagamento?.condicoes?.length === 1 ? meioPagamento?.condicoes[0] : null
              }
        })
      );
    } else {
      dispatch(
        pdvActions.setShowModalMultiploPagamento({
          show: true,
          pagamentoSelecionado: { meioPagamento }
        })
      );
    }
  };

  const handleFecharVenda = () => {
    //TODO: Adicionar validação do pagamento multiplo, se tiver marcado e o saldo a pagar for igual a 0 permite o fechamento tbm
    if (!itemPagamento?.meioPagamento || !itemPagamento.condicaoPagamento) {
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: true,
          confirmaPagamento: venda?.itens?.length > 0,
          pagamentoSelecionado: null
        })
      );
    } else {
      dispatch(pdvActions.setShowModalConfirmaPagamento({ show: true }));
    }

    // if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
    //   if (!itemPagamento?.meioPagamento) {
    //     dispatch(
    //       pdvActions.setShowModalConsultaPagamento({
    //         show: true,
    //         fecharVenda: true,
    //         pagamentoSelecionado: null
    //       })
    //     );
    //   } else {
    //     dispatch(pdvActions.fecharVenda());
    //   }
    // } else {
    //   if (!itemPagamento?.meioPagamento || !itemPagamento.condicaoPagamento) {
    //     dispatch(
    //       pdvActions.setShowModalConsultaPagamento({
    //         show: true,
    //         confirmaPagamento: venda?.itens?.length > 0,
    //         pagamentoSelecionado: null
    //       })
    //     );
    //   } else {
    //     dispatch(pdvActions.setShowModalConfirmaPagamento({ show: true }));
    //   }
    // }
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers} contextMenu tabIndex="-1" className={classes.root}>
      <TopBar />
      <Suspense fallback={<LinearProgress />}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{renderRoutes(route.routes)}</div>
          </div>
        </div>
      </Suspense>
      <FooterBar />
    </HotKeys>
  );
};

PdvLayout.propTypes = {
  route: PropTypes.object
};

export default PdvLayout;
