import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fade, useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyles } from '@material-ui/styles';
import { History as IconHistory, Close as IconClose } from '@material-ui/icons';
import * as MuiIcons from '@material-ui/icons'

import api from '~/services/api';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import {
  formatCurrency,
  formatDate,
  formatDatetime,
  formatNumber
} from '~/utils/format';
import { APP_ERROR, API_BASE_URL, API_NODE_URL } from '~/constants';

import { Header } from './styles';

const StyledTableRowBody = withStyles(theme =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow);

const ModalSaldoEstoqueProduto = React.memo(
  ({ open, data: { produto, saldoEstoque }, onClose }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [estoqueReservado, setEstoqueReservado] = useState([]);
    const [estoqueFuturo, setEstoqueFuturo] = useState([]);
    const [estoques, setEstoques] = useState([]);
    const [tabIndex, setTabIndex] = useState('1');

    const handleChangeTabIndex = async (event, newValue) => {
      setTabIndex(newValue);
      if (newValue === '2' && estoqueReservado.length === 0) {
        await loadEstoqueReservado();
        await loadEstoqueFuturo();
      }
    };

    const loadEstoqueFuturo = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `${API_NODE_URL}/estoque/historico/ultima-ordem-compra/${produto?.id}`
        );
        setEstoqueFuturo([...data]);
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        setLoading(false);
      }
    };

    const loadEstoqueReservado = async () => {
      setLoading(true);
      try {
        const { data } = await api.post(
          `${API_NODE_URL}/faturamento/pedidos/pendentes`,
          {
            codigo: produto?.codigo
          }
        );

        setEstoqueReservado([...data]);
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        setLoading(false);
      }
    };

    const loadSaldoEstoque = async () => {
      setLoading(true);
      try {
        const $select = [
          'estProdutoId',
          'estArmazemId',
          'estoqueReal',
          'estoqueReservado',
          'estoqueFuturo'
        ];
        const $expand = [
          'estProdutoLote',
          'estProduto($select=id,codigo,descricao)',
          'estArmazem($select=id,descricao,tipo)',
          'estProdutoGrade($select=id,gradeEixoLinha,gradeEixoColuna)'
        ];

        const { data } = await api.get(
          `${API_BASE_URL}/v1/consulta/saldoestoque/?$select=${$select.join(
            ','
          )}&$filter=estProdutoId eq ${produto?.id}&$expand=${$expand.join(
            ','
          )}`
        );

        const res = data.filter(estoque => estoque.estArmazem.tipo !== 2);
        setEstoques([...res]);
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (!produto) {
        return;
      }
      loadSaldoEstoque();
    }, [produto]);

    return (
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box style={{ minWidth: 180 }}>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Saldo de estoque
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={onClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Grid item md={12}>
            <Header>
              <Typography variant="caption">Produto</Typography>
              <Typography variant="h3">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Typography>
              <Grid container item md={12} alignItems="center">
                <IconHistory />
                <Typography variant="caption">
                  Ult. Atualização {formatDatetime(new Date())}
                </Typography>
              </Grid>
            </Header>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <TabContext value={tabIndex}>
            <AppBar color="default" position="static">
              <TabList onChange={handleChangeTabIndex} aria-label="Tabs">
                <Tab label="Saldo estoque" value="1" />
                <Tab label="Pedidos" value="2" />
              </TabList>
            </AppBar>
            <TabPanel value="1" style={{ padding: 16 }}>
              <TableContainer component={Paper} style={{ marginBottom: 16 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Armazem</TableCell>
                      {produto?.grade && (
                        <TableCell>Grade linha/coluna</TableCell>
                      )}

                      <TableCell>Lote</TableCell>
                      <TableCell align="center">Estoque real </TableCell>
                      <TableCell align="center">Estoque reserva </TableCell>
                      <TableCell align="center">Estoque futuro </TableCell>
                      <TableCell align="center">Estoque manufatura</TableCell>
                      <TableCell align="center">Estoque empenho</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Box flex={1} justifyContent="center">
                            <CircularProgress />
                            <p>Caregando...</p>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {estoques?.map((item, index) => (
                          <StyledTableRowBody key={index}>
                            <TableCell align="left">
                              {item?.estArmazem?.descricao}
                            </TableCell>
                            {produto?.grade && item?.estProdutoGrade && (
                              <TableCell>
                                {item?.estProdutoGrade?.gradeEixoLinha} /{' '}
                                {item?.estProdutoGrade?.gradeEixoColuna}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              {item?.estProdutoLote?.lote}
                            </TableCell>
                            <TableCell align="center">
                              {formatNumber(item?.estoqueReal)}
                            </TableCell>
                            <TableCell align="center">
                              {formatNumber(item?.estoqueReservado)}
                            </TableCell>
                            <TableCell align="center">
                              {formatNumber(item?.estoqueFuturo)}
                            </TableCell>
                            <TableCell align="center">
                              {formatNumber(item?.estoqueManufatura)}
                            </TableCell>
                            <TableCell align="center">
                              {formatNumber(item?.estoqueEmpenho)}
                            </TableCell>
                          </StyledTableRowBody>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value="2" style={{ padding: 16 }}>
              {loading ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  p={4}
                >
                  <CircularProgress />
                  <p>Caregando...</p>
                </Box>
              ) : (
                <>
                  {estoqueReservado?.length > 0 && (
                    <>
                      <Typography variant={'h4'}>
                        Reservado pelo(s) pedido(s):
                      </Typography>
                      <TableContainer
                        component={Paper}
                        style={{ marginBottom: 16 }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Série/Número</TableCell>
                              <TableCell>Data emissão</TableCell>
                              <TableCell align="center">Quantidade</TableCell>
                              <TableCell align="right">Total pedido</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {estoqueReservado?.map((pedido, index) => (
                              <StyledTableRowBody key={index}>
                                <TableCell>
                                  {pedido?.serie}/{pedido?.numero}
                                </TableCell>
                                <TableCell>
                                  {formatDate(new Date(pedido?.dataEmissao))}
                                </TableCell>
                                <TableCell align="center">
                                  {formatNumber(
                                    pedido?.quantidadeLiberada ?? 0
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(pedido?.valorTotal ?? 0)}
                                </TableCell>
                              </StyledTableRowBody>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}

                  {estoqueFuturo?.length > 0 && (
                    <>
                      <Typography variant={'h4'}>
                        Estoque futuro (Ordem de compra):
                      </Typography>
                      <TableContainer
                        component={Paper}
                        style={{ marginBottom: 16 }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Número</TableCell>
                              <TableCell>Comprador</TableCell>
                              <TableCell align="center">Quantidade</TableCell>
                              <TableCell>Situação</TableCell>
                              <TableCell>Previsão de chegada</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {estoqueFuturo?.map(order => (
                              <StyledTableRowBody>
                                <TableCell>{order?.numero}</TableCell>
                                <TableCell>{order?.comprador}</TableCell>
                                <TableCell align="center">
                                  {formatNumber(order?.itemQuantidade)}
                                </TableCell>
                                <TableCell>
                                  {order?.situacao === 0
                                    ? 'Aguardando aprovação'
                                    : 'Aprovada'}
                                </TableCell>
                                <TableCell>
                                  {formatDate(new Date(order?.previsaoChegada))}
                                </TableCell>
                              </StyledTableRowBody>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </>
              )}
            </TabPanel>
          </TabContext>

          <Paper style={{ margin: '0 16px 16px' }}>
            <Grid
              container
              item
              md={12}
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="caption" align="center">
                  Disponível
                </Typography>
                <Typography align="center">
                  <b style={{ color: '#09A848' }}>
                    {estoques.reduce((total, item) => total + (item.estoqueReal + (item.estoqueReservado * -1)), 0)}
                  </b>
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item>
                <Typography variant="caption" align="center">
                  Real
                </Typography>
                <Typography align="center">
                  <b> {estoques.reduce((total, item) => total + item.estoqueReal, 0)}</b>
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item>
                <Typography variant="caption" align="center">
                  Reservado
                </Typography>
                <Typography align="center">
                  <b> {estoques.reduce((total, item) => total + item.estoqueReservado, 0)}</b>
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item>
                <Typography variant="caption" align="center">
                  Futuro
                </Typography>
                <Typography align="center">
                  <b> {estoques.reduce((total, item) => total + item.estoqueFuturo, 0)}</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} startIcon={<MuiIcons.Close />}>
            Cancelar (ESC)
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ModalSaldoEstoqueProduto;
