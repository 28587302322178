import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as Yup from 'yup';

import api from '~/services/api';
import toast from '~/services/toast';
import { APP_ERROR, API_BASE_URL, API_NODE_URL, APP_SETTINGS } from '~/constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as actions from '~/store/modules/pdv/pdv-actions';

export const ModalCadastroEnderecoCliente = ({
  data,
  clienteId = null,
  showModal,
  onClose,
  callback
}) => {
  const temaAtual = JSON.parse(sessionStorage.getItem(APP_SETTINGS));
  const dispatch = useDispatch();
  const { venda } = useSelector(state => state.pdv);
  const isOnline = useSelector(getApplicationIsOnline());

  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingCidade, setLoadingCidade] = useState(false);
  const [loadingSaving, setLoadingSaving] = useState(false);
  const [intClienteId] = useState(clienteId ?? venda?.intCliente?.id);

  const [endereco, setEndereco] = useState({
    ...data
  });
  const [cidades, setCidades] = useState([]);

  const [validationSchema] = useState(
    Yup.object().shape({
      descricao: intClienteId
        ? Yup.string().required('Descrição é obrigatório')
        : Yup.string().optional(),

      cep: Yup.string().required('CEP é obrigatório'),
      logradouro: Yup.string().required('Logradouro é obrigatório'),
      logradouroNumero: Yup.string().required('Número é obrigatório'),
      bairro: Yup.string().required('Bairro é obrigatório'),
      intCidade: Yup.object().required('Cidade é obrigatório')
    })
  );

  const handleChangeCidade = async value => {
    try {
      setLoadingCidade(true);

      const $select = ['id', 'uf', 'cidade', 'codigoIbge'].join(',');
      const $filter = [
        `contains(codigoIbge,'${value?.toUpperCase()}')`,
        `contains(cidade,'${value?.toUpperCase()}')`
      ].join(' or ');

      const { data } = await api.get(
        `${API_BASE_URL}/v1/integracao/cidade/?$select=${$select?.toString()}&$filter=${$filter?.toString()}`
      );

      setCidades([...data]);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoadingCidade(false);
    }
  };

  const handleChangeCEP = async value => {
    try {
      const cep = value.replace('.', '').replace('-', '');

      if (cep?.length < 8) {
        return;
      }

      setLoadingCep(true);
      const { data: consultaCep } = await api.get(`https://viacep.com.br/ws/${cep}/json/`, {
        transformRequest: [
          (config, headers) => {
            delete headers.Authorization;

            return config;
          }
        ]
      });

      const $select = ['id', 'uf', 'cidade', 'codigoIbge'].join(',');

      if (!consultaCep?.erro) {
        const $filter = [
          `contains(codigoIbge,'${consultaCep?.ibge}')`,
          `contains(cidade,'${consultaCep?.localidade?.toUpperCase()}')`
        ].join(' and ');

        const {
          data: [cidade]
        } = await api.get(
          `${API_BASE_URL}/v1/integracao/cidade/?$select=${$select?.toString()}&$filter=${$filter?.toString()}`
        );

        setCidades([cidade]);
        setEndereco({
          cep: consultaCep?.cep,
          bairro: consultaCep?.bairro,
          logradouro: consultaCep?.logradouro,
          uf: consultaCep?.uf,
          intCidade: { ...cidade },
          intCidadeId: cidade?.id
        });
      } else {
        const { data: _cidades } = await api.get(
          `${API_BASE_URL}/v1/integracao/cidade/?$select=${$select?.toString()}`
        );

        setCidades([..._cidades]);
        setEndereco({
          cep: value
        });
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoadingCep(false);
    }
  };

  const handleSubmit = async values => {
    setLoadingSaving(true);
    try {
      if(values.bairro) values.bairro = values.bairro.replace(/\s+$/, '');
      if(values.cep) values.cep = values.cep.replace(/\s+$/, '');
      if(values.descricao) values.descricao = values.descricao.replace(/\s+$/, '');
      if(values.logradouro) values.logradouro = values.logradouro.replace(/\s+$/, '');
      if(values.logradouroNumero) values.logradouroNumero = values.logradouroNumero.replace(/\s+$/, '');
      if(values.referencia) values.referencia = values.referencia.replace(/\s+$/, '');
      if (intClienteId == null && values?.id == null) {
        if (callback) callback(values);
        setLoadingSaving(false);
        return;
      }

      if(values.cep.length < 8 || values.cep.length < 9) {toast.error("Formato de CEP inválido"); return; }

      const { data } =
        endereco?.id > 0
          ? await api.put(
              `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos/${endereco?.id}`,
              {
                ...values,
                intCidadeId: values?.intCidade?.id ?? null
              }
            )
          : await api.post(
              `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos`,
              {
                ...values,
                intClienteId: venda?.intCliente?.id ?? null,
                intCidadeId: values?.intCidade?.id ?? null
              }
            );

      if (callback) callback({ ...data, ...values });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoadingSaving(false);
    }
  };

  const enabledInputs = () => !loadingCep && values?.cep && values?.cep?.length >= 8;

  return (
    <>
      <Mui.Dialog open={showModal} onClose={onClose} PaperComponent={Mui.Paper} maxWidth={'lg'}>
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Cadastrar endereço
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>

        <Mui.DialogContent style={{ padding: 16, width: 880 }}>
          <Formik
            initialValues={endereco}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Mui.Grid container spacing={4}>
                    <Mui.Grid container item md={12} spacing={4}>
                      <Mui.Grid item md={4}>
                        <Mui.TextField
                          name="cep"
                          label={'CEP'}
                          value={values?.cep ?? ''}
                          error={Boolean(touched.cep && errors.cep)}
                          fullWidth
                          required
                          helperText={touched.cep && errors.cep}
                          placeholder={`00000-000`}
                          onBlur={handleBlur}
                          onChange={e => {
                            const _cep = e.target.value.replace(/[^0-9]/g, '');
                            setFieldValue('cep', _cep);
                            if (isOnline) handleChangeCEP(_cep);
                          }}
                          variant="outlined"
                          disabled={loadingSaving || enabledInputs || loadingCidade}
                          InputLabelProps={{ 
                            shrink: true,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                              fontWeight: 'bold',
                            } 
                          }}
                          inputProps={{
                            maxLength: 8,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                            },
                            endAdornment: (
                              <>
                                {loadingCep ? (
                                  <Mui.CircularProgress color="inherit" size={20} />
                                ) : null}
                              </>
                            )
                          }}
                        />
                      </Mui.Grid>
                      {(
                          <Mui.Grid item md={4}>
                            <Mui.TextField
                              name="descricao"
                              label={'Descrição'}
                              value={values?.descricao ?? ''}
                              error={Boolean(touched.descricao && errors.descricao)}
                              fullWidth
                              required
                              helperText={touched.descricao && errors.descricao}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              disabled={loadingSaving || enabledInputs || loadingCidade}
                              InputLabelProps={{ 
                                shrink: true,
                                style: {
                                  color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                                  fontWeight: 'bold',
                                } 
                              }}
                              inputProps={{
                                maxLength: 40,
                                style: {
                                  color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                                },
                              }}
                            />
                          </Mui.Grid>
                        )}
                    </Mui.Grid>
                    {/* {((!loadingCep && values?.cep && values?.cep?.length >= 8) ||
                      !isOnline) && (
                      <> */}
                    <Mui.Grid container item md={12} spacing={4}>
                      <Mui.Grid item md={8}>
                        <Mui.TextField
                          name="logradouro"
                          label={'Logradouro'}
                          value={values?.logradouro ?? ''}
                          error={Boolean(touched.logradouro && errors.logradouro)}
                          fullWidth
                          required
                          helperText={touched.logradouro && errors.logradouro}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={loadingSaving || enabledInputs || loadingCidade}
                          InputLabelProps={{ 
                            shrink: true,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                              fontWeight: 'bold',
                            } 
                          }}
                          inputProps={{
                            maxLength: 50,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                            },
                          }}
                        />
                      </Mui.Grid>
                      <Mui.Grid item md={4}>
                        <Mui.TextField
                          name="logradouroNumero"
                          label={'Número'}
                          value={values?.logradouroNumero ?? ''}
                          error={Boolean(touched.logradouroNumero && errors.logradouroNumero)}
                          fullWidth
                          required
                          helperText={touched.logradouroNumero && errors.logradouroNumero}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={loadingSaving || enabledInputs || loadingCidade}
                          InputLabelProps={{ 
                            shrink: true,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                              fontWeight: 'bold',
                            } 
                          }}
                          inputProps={{
                            maxLength: 6,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                            },
                          }}
                        />
                      </Mui.Grid>
                    </Mui.Grid>

                    <Mui.Grid container item md={12} spacing={4}>
                      <Mui.Grid item md={12}>
                        <Mui.TextField
                          name="bairro"
                          label={'Bairro'}
                          value={values?.bairro ?? ''}
                          error={Boolean(touched.bairro && errors.bairro)}
                          fullWidth
                          required
                          helperText={touched.bairro && errors.bairro}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={loadingSaving || enabledInputs || loadingCidade}
                          InputLabelProps={{ 
                            shrink: true,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                              fontWeight: 'bold',
                            } 
                          }}
                          inputProps={{
                            maxLength: 30,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                            },
                          }}
                        />
                      </Mui.Grid>
                    </Mui.Grid>

                    <Mui.Grid container item md={12} spacing={4}>
                      <Mui.Grid item md={12}>
                        <Autocomplete
                          id="cidade-autocomplete"
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={option =>
                            `${option?.codigoIbge} - ${option?.cidade}/${option?.uf}`
                          }
                          options={cidades}
                          value={values?.intCidade ?? null}
                          loading={loadingCidade}
                          loadingText="Carregando..."
                          noOptionsText="Nenhum registro encontrado"
                          onInputChange={(_, value) => handleChangeCidade(value)}
                          onChange={(event, value) => {
                            event.persist();

                            setFieldValue('intCidade', value);
                            setFieldValue('intCidadeId', value?.id);
                          }}
                          renderInput={params => (
                              <Mui.TextField
                                {...params}
                                label={'Cidade'}
                                placeholder={'Consultar....'}
                                fullWidth
                                disabled={loadingSaving || enabledInputs || loadingCidade}
                                variant="outlined"
                                InputLabelProps={{ 
                                  shrink: true,
                                  style: {
                                    color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                                    fontWeight: 'bold',
                                  } 
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                                  },
                                  endAdornment: (
                                    <React.Fragment>
                                      {loadingCidade ? (
                                        <Mui.CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                          )}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                    {/* </>
                    )} */}
                  </Mui.Grid>

                  <Mui.Grid container item md={12} spacing={4}>
                      <Mui.Grid item md={12}>
                        <Mui.TextField
                          name="referencia"
                          label={'Referência'}
                          value={values?.referencia ?? ''}
                          error={Boolean(touched.referencia && errors.referencia)}
                          fullWidth
                          helperText={touched.referencia && errors.referencia}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={loadingSaving || enabledInputs || loadingCidade}
                          InputLabelProps={{ 
                            shrink: true,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                              fontWeight: 'bold',
                            } 
                          }}
                          inputProps={{
                            maxLength: 300,
                            style: {
                              color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF"
                            },
                          }}
                        />
                      </Mui.Grid>
                    </Mui.Grid>

                  <Mui.Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  <Mui.Grid container item direction="row" justify="flex-end" alignItems="center">
                    <Mui.Box p={1}>
                      <Mui.Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={loadingSaving}
                        autoFocus
                      >
                        {loadingSaving ? (
                          <>
                            <span style={{ paddingLeft: 8 }}>{'Salvando ...'}</span>
                          </>
                        ) : (
                          <>
                            <MuiIcons.CheckCircle />
                            <span style={{ paddingLeft: 8 }}>{'Confirmar'}</span>
                          </>
                        )}
                      </Mui.Button>

                      <Mui.Button
                        size="large"
                        startIcon={<MuiIcons.Close />}
                        disabled={loadingSaving}
                        onClick={onClose}
                        style={{ marginLeft: 16 }}
                      >
                        Cancelar (ESC)
                      </Mui.Button>
                    </Mui.Box>
                  </Mui.Grid>
                </form>
              </>
            )}
          </Formik>
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
};

const ModalListaEnderecoCliente = () => {
  const dispatch = useDispatch();
  const { venda, showModalEnderecoCliente } = useSelector(state => state.pdv);

  const [showModalCadastrarEndereco, setShowModalCadastrarEndereco] = useState(
    showModalEnderecoCliente?.cadastroEndereco
      ? { show: true, data: showModalEnderecoCliente?.data }
      : { show: false }
  );
  const [loading, setLoading] = useState(false);
  const [endereco, setEndereco] = useState();
  const [clienteEnderecos, setClienteEnderecos] = useState([]);

  const handleClose = () => {
    dispatch(actions.setShowModalEnderecoCliente({ show: false }));
  };

  const handleRemoveEndereco = async e => {
    const { isConfirmed } = await toast.confirm(
      'ATENÇÃO',
      'Deseja excluir este registro?</br></br>Certifique-se de que realmente deseja fazer isso, pois esta ação é irreversível.',
      {
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }
    );

    if (isConfirmed) {
      await api.delete(
        `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos/${e?.id}`
      );
      toast.success('Registro removido com sucesso!');
      await loadData();
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos/`
      );

      setClienteEnderecos([...data]);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (venda?.intCliente?.id > 0) {
      await loadData();
    } else {
      let _endereco = null;
      if (showModalEnderecoCliente?.type === 'endereco') {
        _endereco = {
          logradouro: venda?.enderecoLogradouro,
          logradouroNumero: venda?.enderecoLogradouroNumero,
          bairro: venda?.enderecoBairro,
          cep: venda?.enderecoCep,
          intCidade: venda?.enderecoIntCidade,
          intCidadeId: venda?.enderecoIntCidade?.id,
          uf: venda?.enderecoUf
        };
      } else {
        _endereco = {
          logradouro: venda?.entregaLogradouro,
          logradouroNumero: venda?.entregaLogradouroNumero,
          bairro: venda?.entregaBairro,
          cep: venda?.entregaCep,
          intCidade: venda?.entregaIntCidade,
          intCidadeId: venda?.entregaIntCidade?.id,
          uf: venda?.entregaUf
        };
      }

      setEndereco({ ..._endereco });
      setShowModalCadastrarEndereco({ show: true, data: _endereco });
    }
  }, [loadData, venda?.intCliente?.id]);

  return (
    <>
      <Mui.Dialog
        open={showModalEnderecoCliente.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Mui.Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Escolha o endereço
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>

        <Mui.DialogContent style={{ padding: 16, width: 800 }} dividers>
          <Mui.Box p={4}>
            {loading ? (
              <>
                <Mui.Box display="flex" justifyContent="center" alignItems="center" p={8}>
                  <Mui.CircularProgress variant="indeterminate" color="primary" />
                </Mui.Box>
              </>
            ) : (
              <>
                {clienteEnderecos.length === 0 && <>Nenhum endereço encontrado</>}
                <Mui.List>
                  {clienteEnderecos.map((item, index) => (
                    <>
                      <Mui.ListItem key={index}>
                        <Mui.Grid container>
                          <Mui.Grid item xs={1}>
                            <Mui.Radio
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                              checked={
                                showModalEnderecoCliente.type === 'endereco'
                                  ? venda?.intClienteEnderecoId === item?.id
                                  : venda?.entregaIntClienteEnderecoId === item?.id
                              }
                              onChange={() => {
                                dispatch(
                                  showModalEnderecoCliente.type === 'endereco'
                                    ? actions.selecionarEndereco({ ...item })
                                    : actions.selecionarEnderecoEntrega({ ...item })
                                );
                              }}
                            />
                          </Mui.Grid>
                          <Mui.Grid item xs={8}>
                            <Mui.Box px={3}>
                              <Mui.Typography>{item?.descricao}</Mui.Typography>
                              <Mui.Typography style={{ fontSize: 14, width: '270px' }}>
                                {item?.logradouro}
                              </Mui.Typography>
                              <Mui.Typography style={{ fontSize: 14 }}>
                                N. {item?.logradouroNumero},{item?.bairro}
                              </Mui.Typography>
                              <Mui.Typography style={{ fontSize: 14 }}>
                                {item?.intCidade?.cidade}
                                {'-'}
                                {item?.intCidade?.uf} {''} {item?.cep}
                              </Mui.Typography>
                            </Mui.Box>
                          </Mui.Grid>
                          <Mui.Grid item xs={3}>
                            <Mui.IconButton
                              onClick={() => {
                                setEndereco(item);
                                setShowModalCadastrarEndereco({ show: true, data: item });
                              }}
                            >
                              <MuiIcons.Edit />
                            </Mui.IconButton>
                            <Mui.IconButton
                              onClick={() => {
                                handleRemoveEndereco(item);
                              }}
                            >
                              <MuiIcons.Delete />
                            </Mui.IconButton>
                            <Mui.IconButton
                              onClick={() => {
                                dispatch(
                                  showModalEnderecoCliente.type === 'endereco'
                                    ? actions.selecionarEndereco({ ...item })
                                    : actions.selecionarEnderecoEntrega({ ...item })
                                );
                                handleClose();
                              }}
                            >
                              <MuiIcons.ArrowForward />
                            </Mui.IconButton>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.ListItem>
                      <Mui.Divider />
                    </>
                  ))}
                </Mui.List>
              </>
            )}
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box display="flex" p={2} justifyContent="center">
            <Mui.Button
              variant="contained"
              className="button-success"
              startIcon={<MuiIcons.AddCircle />}
              onClick={() => {
                setEndereco({ cep: '' });
                setShowModalCadastrarEndereco({ show: true, data: { cep: '' } });
              }}
            >
              <span style={{ paddingLeft: 8 }}> Adicionar endereço</span>
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>

      {showModalCadastrarEndereco.show && (
        <ModalCadastroEnderecoCliente
          showModal={showModalCadastrarEndereco.show}
          onClose={() => {
            setShowModalCadastrarEndereco({ show: false });
            if (!venda?.intCliente?.id)
              dispatch(actions.setShowModalEnderecoCliente({ show: false }));
          }}
          data={showModalCadastrarEndereco?.data}
          callback={async values => {
            dispatch(
              showModalEnderecoCliente.type === 'endereco'
                ? actions.selecionarEndereco({ ...values })
                : actions.selecionarEnderecoEntrega({ ...values })
            );

            setShowModalCadastrarEndereco({ show: false });
            if (venda?.intCliente?.id > 0) {
              await loadData();
            } else {
              dispatch(actions.setShowModalEnderecoCliente({ show: false }));
            }
          }}
        />
      )}
    </>
  );
};

export default ModalListaEnderecoCliente;
