import api from '~/services/api';
import { API_BASE_URL } from '~/constants';

class ClienteService {
  query(queryText, opts) {
    const select = [
      'codigoInterno',
      'razaoSocial',
      'fantasia',
      'desativado',
      'mensagemVenda',
      'telefone',
      'celular',
      'enderecoComplemento',
      'enderecoUf',
      'enderecoCep',
      'enderecoLogradouro',
      'enderecoLogradouroNumero',
      'enderecoBairro',
      'pjCnpj',
      'pfCpf',
      'natureza',
      'id'
    ].join(',');
    const expand = [
      'enderecoIntCidade',
      'fatVendedor($select=id,nome)',
      'fatTabelavenda($select=id,descricao)',
      'intClienteGrupo($expand=fatTabelavenda($select=id,descricao))',
      'clienteMeioPagamento($select=id,finMeiopagamentoId,intClienteId)',
    ].join(',');

    const consultaPorCodigo = !isNaN(parseInt(queryText));
    let filter = '';

    if(queryText?.length > 3) {
      const numero = Number(queryText);
      filter = ['pjCnpj', 'pfCpf', 'razaoSocial', 'fantasia']
      .map(field => `contains(${field}, %27${queryText.toUpperCase()}%27)`)
      .join(' or ');
      filter = filter + (numero ? ` or codigoInterno eq ${queryText.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '')}` : '');
    }

    if (consultaPorCodigo && queryText?.length <= 3) {
      filter += ` ${queryText?.length > 3 ? 'or' : ''} codigoInterno eq ${queryText.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '')} `;
    }

    return api.get(
      `${API_BASE_URL}/v1/consulta/cliente?$orderby=razaoSocial&$select=${select}&$filter=${filter}&$expand=${expand}`,
      opts
    );
  }
}

export default new ClienteService();
