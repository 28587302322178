import React, { useEffect, createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getState, resetState } from 'redux-localstore';
import { withWidth } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import * as Mui from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import toast from '~/services/toast';
import { PEDIDO_TIPO_BLOQUEIO, STATUS_PDV, TIPO_DOCUMENTO } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import ModalAtalhosPdv from './modal/ModalAtalhosPdv';
import ModalConsultaProduto from './modal/ModalConsultaProduto';
import ModalIdentificarCliente from './modal/ModalIdentificarCliente';
import ModalEnderecoCliente from './modal/ModalEnderecoCliente';
import ModalConsultaCliente from './modal/ModalConsultaCliente';
import ModalConsultaVendedor from './modal/ModalConsultaVendedor';
import ModalConsultaProfissional from './modal/ModalConsultaProfissional';
import ModalConsultaOperacao from './modal/ModalConsultaOperacao';
import ModalExpedicaoMercadoria from './modal/ModalExpedicaoMercadoria';
import ModalConsultaPagamento from './modal/ModalConsultaPagamento';
import ModalConfirmaPagamento from './modal/ModalConfirmaPagamento';
import ModalInformacaoComplementar from './modal/ModalInformacaoComplementar';
import ModalImportarDocumento from './modal/ModalImportarDocumento';
import ModalRetentativasTransmissao from './modal/ModalRetentativasTransmissao';
import ModalMenuTEF from './modal/ModalMenuTEF';
import ModalIdentiticacaoPDV from './modal/ModalIdentificacaoPDV';
import ModalMultiploPagamento from './modal/ModalMultiploPagamento';
import ModalListaPagamentos from './modal/ModalListaPagamentos';
import ModalPreviewDanfe from './modal/ModalPreviewDanfe';
import ModalConsultaRapidaProduto from './modal/ModalConsultaRapidaProduto';
import ModalDesconto from './modal/ModalDesconto';
import ModalAcrescimo from './modal/ModalAcrescimo';
//LOADING
import ModalLoadingMensagem from './modal/ModalLoadingMensagem';
import ModalLoadingSalvamento from './modal/ModalLoadingSalvamento';
//BLOQUEIO
import ModalLiberacaoEstoque from '../PainelVenda/modal/ModalLiberacaoEstoque';
import ModalLiberacaoComercial from '../PainelVenda/modal/ModalLiberacaoComercial';
import ModalLiberacaoDesconto from '../PainelVenda/modal/ModalLiberacaoDesconto';
import ModalLiberacaoCredito from '../PainelVenda/modal/ModalLiberacaoCredito';

import IdentificacaoView from './views/Identificacao';
import VendeItemView from './views/VendeItem';

import ListaItens from './components/ListaItens';
import Totalizadores from './components/Totalizadores';
import * as S from './styles';
import FilaDocumentos from '~/components/FilaDocumentos';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';
import ModalFrete from './modal/ModalFrete';

// const shortcut = require('electron-localshortcut');
// const electron = window.require('electron');
// const { ipcRenderer, app } = electron;

const liberacaoModais = {
  [PEDIDO_TIPO_BLOQUEIO.BloqueadoEstoque]: ModalLiberacaoEstoque,
  [PEDIDO_TIPO_BLOQUEIO.BloqueadoRegraComercial]: ModalLiberacaoComercial,
  [PEDIDO_TIPO_BLOQUEIO.BloqueadoRegraDesconto]: ModalLiberacaoDesconto,
  [PEDIDO_TIPO_BLOQUEIO.BloqueadoInadimplencia]: ModalLiberacaoDesconto,
  [PEDIDO_TIPO_BLOQUEIO.BloqueadoCredito]: ModalLiberacaoCredito
};

const TelaVenda = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const containerRef = createRef();
  const {
    status,
    setFocusInputPesquisaProduto,
    showModalLoadingSalvamento,
    showModalLoadingMensagem,
    showModalConsultaProduto,
    showModalConsultaCliente,
    showModalConsultaVendedor,
    showModalConsultaProfissional,
    showModalConsultaOperacao,
    showModalEnderecoCliente,
    showModalConfirmaPagamento,
    showModalInformacaoComplementar,
    showModalExpedicaoMercadoria,
    showModalConsultaPagamento,
    showModalIdentificarCliente,
    showModalAtalhosPdv,
    showModalLiberacaoPedido,
    showModalMultiploPagamento,
    showModalListaPagamentos,
    showModalImportarDocumento,
    showModalPreviewDanfe,
    showModalConsultaRapidaProduto,
    showModalDesconto,
    showModalAcrescimo,
    showModalFrete
  } = useSelector(state => state.pdv);
  const { showModalMenuTef } = useSelector(state => state.app);

  const obrigaMeioPagamento = useSelector(selectorParametroAsBoolean('PP001'));  
  const parametroConfirmaLogin = useSelector(selectorParametroAsBoolean('PP002'));
  const exibirModalCliente = useSelector(selectorParametroAsBoolean('PP003'));  

  const renderModalLiberacao = () => {
    const ModalLiberacaoPedido = liberacaoModais[showModalLiberacaoPedido?.bloqueio];

    if (!ModalLiberacaoPedido) {
      return null;
    }

    return (
      <ModalLiberacaoPedido
        id={showModalLiberacaoPedido?.id}
        isDocumentoOffline={showModalLiberacaoPedido?.isDocumentoOffline}
        open={showModalLiberacaoPedido?.show}
        onClose={() => {
          dispatch(pdvActions.setShowModalLiberacaoPedido({ show: false, id: null }));

          if (showModalLiberacaoPedido?.callback) {
            showModalLiberacaoPedido?.callback();
          }
        }}
      />
    );
  };

  useEffect(() => {
    console.log(state);
    if(obrigaMeioPagamento && exibirModalCliente && !state?.venda?.fatCondicaopagamentoId && state?.tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !state?.venda?.id && !state?.venda?.importado) {
      dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
    } else if(obrigaMeioPagamento && !exibirModalCliente && !state?.venda?.fatCondicaopagamentoId && state?.tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !state?.venda.id && !state?.venda?.importado) {
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: true
        })
      );
    } else if(!obrigaMeioPagamento && exibirModalCliente && !state?.venda?.editar && !state?.venda?.importado) {
      dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
    }
  }, []);

  useEffect(() => {
    if (!setFocusInputPesquisaProduto) {
      containerRef?.current?.focus();
    }
  }, [setFocusInputPesquisaProduto]);

  useEffect(() => {
    (async () => {
      const localStoreage = JSON.parse(sessionStorage.getItem('reduxStore'));
      if (localStoreage?.pdv?.venda?.itens?.length === 0) {
        resetState();
      }
      let tipoDocumento = state?.tipoDocumento ?? TIPO_DOCUMENTO.PEDIDO;
      let venda = state?.venda ? { ...state.venda } : null;
      if (!venda && localStoreage?.pdv?.venda?.itens?.length > 0) {
        dispatch(pdvActions.setShowModalIdentificarCliente({ show: false }));
        dispatch(pdvActions.setShowModalConsultaPagamento({ show: false }));
        const { isConfirmed } = await toast.confirm(
          'ATENÇÃO',
          'Existe uma venda iniciada, deseja recuperar a venda anterior?',
          {
            icon: 'warning',
            confirmButtonText: 'Nova venda',
            cancelButtonText: 'Recuperar'
          }
        );

        if(isConfirmed) {
          if(tipoDocumento === TIPO_DOCUMENTO.PEDIDO && exibirModalCliente) dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
          if(obrigaMeioPagamento && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !exibirModalCliente && !parametroConfirmaLogin) {
            dispatch(pdvActions.setShowModalConsultaPagamento({ show: true }));
          }
        }

        if (!isConfirmed) {
          tipoDocumento = localStoreage ? localStoreage?.pdv?.tipoDocumento : null;
          venda = {
            ...localStoreage?.pdv?.venda
          };
        }
        resetState();
      }

      if (tipoDocumento) {
        dispatch(
          pdvActions.iniciarVenda({
            tipoDocumento,
            venda
          })
        );
      }
    })();
    return () => {
      dispatch(pdvActions.limparVenda());
    };
  }, []);

  return (
    <>
      <S.Root ref={containerRef}>
        <S.Content>
          <S.MainContainer>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              {status === STATUS_PDV.IDENTIFICACAO && <IdentificacaoView />}
              {status === STATUS_PDV.VENDA && <VendeItemView />}
            </PerfectScrollbar>
          </S.MainContainer>
          <Mui.Divider orientation="vertical" flexItem />
          <S.ItensContainer>
            <Mui.Box
              style={{
                height: 'calc(100% - 154px)',
                flex: '50%'
              }}
            >
              <ListaItens />
            </Mui.Box>
            <Mui.Divider />
            <Totalizadores />
          </S.ItensContainer>
        </S.Content>
      </S.Root>

      {showModalLoadingSalvamento?.show && <ModalLoadingSalvamento />}
      {showModalLoadingMensagem?.show && <ModalLoadingMensagem />}
      {/* {showModalRetentativasTransmissao.show && (
        <ModalRetentativasTransmissao
          showModal={showModalRetentativasTransmissao?.show}
          onClose={() => {
            setShowModalRetentativasTransmissao({ show: false });
          }}
        />
      )} */}
      {showModalMenuTef?.show && <ModalMenuTEF />}
      {showModalConsultaVendedor?.show && <ModalConsultaVendedor />}
      {showModalConsultaProfissional?.show && <ModalConsultaProfissional />}
      {showModalConsultaOperacao?.show && <ModalConsultaOperacao />}
      {showModalConsultaProduto?.show && <ModalConsultaProduto />}
      {showModalEnderecoCliente?.show && <ModalEnderecoCliente />}
      {showModalConfirmaPagamento?.show && <ModalConfirmaPagamento />}
      {showModalExpedicaoMercadoria?.show && <ModalExpedicaoMercadoria />}
      {showModalInformacaoComplementar?.show && <ModalInformacaoComplementar />}
      {showModalConsultaCliente?.show && <ModalConsultaCliente />}
      {showModalIdentificarCliente?.show && <ModalIdentificarCliente />}
      {showModalConsultaPagamento?.show && <ModalConsultaPagamento />}
      {showModalAtalhosPdv?.show && <ModalAtalhosPdv />}
      {showModalMultiploPagamento?.show && <ModalMultiploPagamento />}
      {showModalListaPagamentos?.show && <ModalListaPagamentos />}
      {showModalPreviewDanfe?.show && (
        <ModalPreviewDanfe
          data={showModalPreviewDanfe?.data}
          showModal={showModalPreviewDanfe?.show}
          onClose={() => dispatch(pdvActions.setShowModalPreviewDanfe({ show: false }))}
        />
      )}
      {showModalImportarDocumento?.show && (
        <ModalImportarDocumento
          type={showModalImportarDocumento?.type}
          show={showModalImportarDocumento?.show}
          onClose={() => dispatch(pdvActions.setShowModalImportarDocumento({ show: false }))}
          callback={({ tipoDocumento, venda }) => {
            dispatch(
              pdvActions.iniciarVenda({
                tipoDocumento,
                venda
              })
            );
          }}
        />
      )}
      {showModalConsultaRapidaProduto?.show && (
        <ModalConsultaRapidaProduto
          data={showModalConsultaRapidaProduto?.data}
          show={showModalConsultaRapidaProduto?.show}
          onClose={data => {
            dispatch(pdvActions.setShowModalConsultaRapidaProduto({ show: false }));
          }}
        />
      )}
      {showModalLiberacaoPedido?.show && renderModalLiberacao()}
      {showModalDesconto?.show && <ModalDesconto />}
      {showModalAcrescimo?.show && <ModalAcrescimo />}
      {showModalFrete?.show && <ModalFrete />}
      <FilaDocumentos />
    </>
  );
};

export default withWidth()(TelaVenda);
